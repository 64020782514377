import React, { createContext, useContext, useEffect, useState } from "react";
import { getReported } from "services/reported-service";

export const ReportedContext = createContext();

export const ReportedProvider = ({ children }) => {
  const [reported, setReported] = useState([]);
  const [reportedUsers, setReportedUsers] = useState([]);
  const [filter, setFilter] = useState("unresolved");

  useEffect(() => {
    getReported(setReported);
  }, [])

  useEffect(() => {
    if (filter==='unresolved') {
      setReportedUsers(reported.filter((report) => !report.resolved));
    } else {
      setReportedUsers(reported.filter((report) => report.resolved));
    }
  }, [filter, reported])

  return (
    <ReportedContext.Provider
      value={{
        filter, setFilter,
        reportedUsers, setReportedUsers,
      }}
    >
      {children}
    </ReportedContext.Provider>
  );
};

export const useReported = () => useContext(ReportedContext);
