import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"
import "firebase/compat/functions"

const firebaseConfig = {
  apiKey: "AIzaSyC_YuR45IzZCEll5qxngOnsLe-Q4hWHkGg",
  authDomain: "foreverx-dev.firebaseapp.com",
  projectId: "foreverx-dev",
  storageBucket: "foreverx-dev.appspot.com",
  messagingSenderId: "876434592525",
  appId: "1:876434592525:web:db4f8d5bc13c3c38325644"
}

const app = firebase.initializeApp(firebaseConfig)

export const firestore = app.firestore()
export const time = firebase.firestore.FieldValue.serverTimestamp()
export const auth = app.auth()
export const storage = app.storage()
export const functions = app.functions()

// export { firestore, auth, storage, time }
export default app
