import { firestore, storage } from "utils/firebase";

export const getHeardAboutUs = async (setFeedback) => {
  const querySnapshot = await firestore
  .collection("hearAboutUs")
  .orderBy('createdAt', 'desc')
  .get()
  .catch((e) => {
    console.log('error getting heard about us docs', e);
  });

  const reported = [];

  querySnapshot.forEach((doc) => {
    reported.push({ ...doc.data(), id: doc.id });
  });

  let promises = reported.map((item, index) => getUsersPromise(item));

  Promise.all(promises).then((users) => {
    const reports = users.filter((x) => x !== null);
    setFeedback(reports);
  });
};

const getUsersPromise = (report, reportedUser) => {
  return new Promise(async (resolve, reject) => {
    let userDoc = await firestore.collection("users").doc(report.userId).get();

    if (userDoc.exists) {
      let user = {
        id: userDoc.id,
        ...userDoc.data(),
      };

      report.user = user;
      const profilePicRef = storage
        .ref()
        .child("defaultPictures/" + user.id + ".jpg");
      const url = await profilePicRef.getDownloadURL().catch((e) => {});
      if (!url) resolve(null);
      report.user.image = url;
      resolve(report);
    } else {
      resolve(null);
    }
  });
};

// export const resolveReport = async (report, resolved) => {
//   await firestore.collection("reportedUsers").doc(report.id).update({resolved});
// }
