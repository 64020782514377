import React, { createContext, useContext, useEffect, useState } from "react";
import { getFeedback } from "services/feedback-service";

export const FeedbackContext = createContext();

export const FeedbackProvider = ({ children }) => {
  const [feedback, setFeedback] = useState([]);
  const [feedbackUsers, setFeedbackUsers] = useState([]);
  const [filter, setFilter] = useState("unresolved");

  useEffect(() => {
    getFeedback(setFeedback);
  }, [])

  useEffect(() => {
    if (filter==='unresolved') {
      setFeedbackUsers(feedback.filter((report) => !report.resolved));
    } else {
      setFeedbackUsers(feedback.filter((report) => report.resolved));
    }
  }, [filter, feedback])

  return (
    <FeedbackContext.Provider
      value={{
        filter, setFilter,
        feedbackUsers, setFeedbackUsers,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

export const useFeedback = () => useContext(FeedbackContext);
