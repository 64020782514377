import { firestore, auth, storage } from "utils/firebase";
import { getUsersName, unique } from "utils/helpers";

export const getReported = async (setReported) => {
  const querySnapshot = await firestore.collection("reportedUsers").get();

  const reported = [];

  querySnapshot.forEach((doc) => {
    reported.push({ ...doc.data(), id: doc.id });
  });

  let promises = reported.map((item, index) => getUsersPromise(item));

  Promise.all(promises).then((users) => {
    const merge = users.flat(1);
    const reports = merge.filter((x) => x !== null);
    setReported(reports);
  });
};

const getUsersPromise = (report, reportedUser) => {
  return new Promise(async (resolve, reject) => {
    let userDoc = await firestore
      .collection("users")
      .doc(reportedUser ? report.reportedUser : report.userId)
      .get();

    if (userDoc.exists) {
      let user = {
        id: userDoc.id,
        ...userDoc.data(),
      };

      report.user = user;
      const profilePicRef = storage
        .ref()
        .child("defaultPictures/" + user.id + ".jpg");
      const url = await profilePicRef.getDownloadURL();
      report.user.image = url;
    } else {
      firestore.collection("reportedUsers").doc(report.id).delete();
      resolve(null);
    }

    let reportedUserDoc = await firestore
      .collection("users")
      .doc(report.reportedUser)
      .get();

    if (reportedUserDoc.exists) {
      let user = {
        id: reportedUserDoc.id,
        ...reportedUserDoc.data(),
      };
      report.reportedUser = user;
      const profilePicRef = storage
        .ref()
        .child("defaultPictures/" + user.id + ".jpg");
      const url = await profilePicRef.getDownloadURL();
      report.reportedUser.image = url;
      resolve(report);
    } else {
      firestore.collection("reportedUsers").doc(report.id).delete();
      resolve(null);
    }
  });
};

export const resolveReport = async (report, resolved) => {
  await firestore.collection("reportedUsers").doc(report.id).update({resolved});
}
