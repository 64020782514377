import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Table from "components/Common/TableComponent/Table/Table";
import loginLeft from "assets/images/loginLeft.jpg";
import { useNavigate } from "react-router-dom";
import { useUsers } from "context/UserContext";
import {
  ActivityIndicator,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native-web";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import FormInput from "components/Inputs/FormInput";
import * as FaIcons from "react-icons/fa";
import { useForeverX } from "context/ForeverXContext";
import { getAllUsers, searchUsers } from "services/user-service";
import { Links } from "constants/Links";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { getAge, getUserInfo } from "utils/helpers";
import moment from "moment";
import { ExportToCsv } from "export-to-csv";
import { formatUserReport } from "utils/exports-helper";

const { height } = Dimensions.get("window");
const pageSize = 10;

const options = [
  { value: "all", label: "All" },
  { value: "verification", label: "Need Verification" },
];

export const UsersListing = () => {
  const navigate = useNavigate();
  const { companyData } = useForeverX();

  const {
    userData = [],
    nextPage,
    loading,
    setSelectedUser,
    usersWhoNeedVerification,
    selectedView,
    setSelectedView,
    page,
    setPage,
    search,
    setSearch,
  } = useUsers();

  // Table related part
  const [rows, setRows] = useState(userData);

  useEffect(() => {
    if (selectedView === "all") {
      if (search) {
        setPage(1);
        searchUsers(search, setRows);
      } else {
        setRows(userData);
      }
    } else {
      if (search) {
        let filtered = usersWhoNeedVerification.filter(
          (x) =>
            x.firstName.toLowerCase().includes(search) ||
            x.lastName.toLowerCase().includes(search)
        );
        setRows(filtered);
      } else {
        setRows(usersWhoNeedVerification);
      }
    }
  }, [search, userData, selectedView, usersWhoNeedVerification]);

  function userSelected(user) {
    setSelectedUser(user);
    navigate(Links.UserDetail);
  }

  function exportPressed() {
    // console.log("export pressed");
    let confirm = window.confirm("Are you sure you want to export?");
    if (confirm) {
      // console.log("exporting");
      getAllUsers(exportCSV);
    }
  }

  const csvExporter = new ExportToCsv({
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    filename: `Users-${new Date().toLocaleDateString()}`,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  });

  async function exportCSV(users) {
    // const exportData = await formatUserReport(users);
    const temp = [];

    users.forEach((item) => {
      temp.push({
        Name: item.firstName,
        Gender: item.gender,
        GenderPreference: item.matchPreferenceGender,
        Age: getAge(item)?.value,
        Profession:
          item?.profession === 22
            ? item?.otherProfession + "(Other)"
            : getUserInfo("profession", item?.profession),
        City: item.currentCity,
        State: item?.state,
        // Country: item.country,
        ResX: item.profession === 15 ? "Y" : "",
        SearchRadius: item.radius,
        Email: item.email,
      });
    });

    temp && temp?.length > 0 && csvExporter.generateCsv(temp);
  }

  const currentTableData = () => {
    const firstPageIndex = (page - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return rows.slice(firstPageIndex, lastPageIndex);
  };

  const totalCount = search
    ? rows?.length
    : selectedView === "all"
      ? companyData?.activeUserCount
      : usersWhoNeedVerification?.length;
  const totalPageCount = Math.ceil(totalCount / pageSize);
  const startCount = (page - 1) * pageSize + 1;
  const endCount = startCount + currentTableData()?.length - 1;

  if (loading) {
    return (
      <View
        style={{
          height: height - 100,
          width: "100%",
          flexDirection: "column",
          backgroundColor: COLORS.primary.white,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={COLORS.primary.blue}
        />
      </View>
    );
  }

  return (
    <View style={styles.tableContainer}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingHorizontal: 24,
          marginTop: 12,
        }}
      >
        <FaIcons.FaSearch size={16} color={COLORS.primary.darkGray} />
        <div className="flex-1" style={{ marginRight: 12 }}>
          <FormInput
            type="search"
            placeholder="Search user by name"
            value={search}
            setValue={setSearch}
          />
        </div>
        <CustomPicker
          style={{ maxWidth: 175 }}
          mandatory
          value={selectedView}
          onChange={(value) => setSelectedView(value)}
          options={options}
        />
        <TouchableOpacity
          onPress={() => exportPressed()}
          style={{
            marginLeft: 12,
            padding: 4,
          }}
        >
          <FaIcons.FaDownload size={16} color={COLORS.primary.darkGray} />
        </TouchableOpacity>
      </View>
      {currentTableData() && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <PrimaryText fontSize={24} fontWeight={800}></PrimaryText>
              </TableCell>
              <TableCell>
                <PrimaryText fontSize={24} fontWeight={800}>
                  Name
                </PrimaryText>
              </TableCell>
              <TableCell>
                <PrimaryText fontSize={24} fontWeight={800}>
                  Verified
                </PrimaryText>
              </TableCell>
              <TableCell>
                <PrimaryText fontSize={24} fontWeight={800}>
                  Created At
                </PrimaryText>
              </TableCell>
              <TableCell>
                <PrimaryText fontSize={24} fontWeight={800}>
                  {selectedView === "all" ? "Last Login" : "Verification Requested"}
                </PrimaryText>
              </TableCell>
              <TableCell>
                <PrimaryText fontSize={24} fontWeight={800}>
                  Age
                </PrimaryText>
              </TableCell>
              <TableCell>
                <PrimaryText fontSize={24} fontWeight={800}>
                  Gender
                </PrimaryText>
              </TableCell>
              <TableCell>
                <PrimaryText fontSize={24} fontWeight={800}>
                  Profession
                </PrimaryText>
              </TableCell>
              <TableCell>
                <PrimaryText fontSize={24} fontWeight={800}>
                  State
                </PrimaryText>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentTableData().map((item, index) => {
              return (
                <TableRow
                  hover
                  key={item.id}
                  onClick={() => userSelected(item)}
                >
                  <TableCell style={{ height: 50, padding: 0 }}>
                    <img
                      src={item.image ? item.image : loginLeft}
                      style={{
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {item.firstName} {item.lastName}
                    </PrimaryText>
                  </TableCell>
                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {item.verified ? "Y" : ""}
                    </PrimaryText>
                  </TableCell>
                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {item.createdAt
                        ? moment(item.createdAt.toDate()).format("MMM DD YYYY")
                        : ""}
                    </PrimaryText>
                  </TableCell>
                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {selectedView === "all" ? (item.lastLogin
                        ? moment(item.lastLogin.toDate()).format("MMM DD YYYY")
                        : "") : (item.manualVerificationRequestDate ? moment(item.manualVerificationRequestDate.toDate()).format("MMM DD YYYY") : "")}
                    </PrimaryText>
                  </TableCell>
                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {getAge(item)?.value}
                    </PrimaryText>
                  </TableCell>
                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {item.gender}
                    </PrimaryText>
                  </TableCell>

                  {/* <TableCell>{item.isPremium ? "Y" : ""}</TableCell> */}
                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {getUserInfo("profession", item?.profession)}
                    </PrimaryText>
                  </TableCell>
                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {item.state}
                    </PrimaryText>
                  </TableCell>
                  {/* <TableCell
                      className="moreOptionsCell cursorPointer"
                      onClick={() =>
                        history.push({
                          pathname: `/users/modify-user/${item.id}`,
                          data: {
                            firstName: item.firstName,
                            lastName: item.lastName,
                            img: item.image,
                            sus: item.suspend,
                          },
                        })
                      }
                    >
                      Edit
                    </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      <View
        style={{ flexDirection: "row", alignSelf: "flex-end", marginTop: 12 }}
      >
        <TouchableOpacity onPress={() => (page > 1 ? setPage(page - 1) : null)}>
          <FaIcons.FaChevronLeft size={20} color={COLORS.primary.darkGray} />
        </TouchableOpacity>

        <View style={{ width: 125, alignItems: "center" }}>
          <PrimaryText fontSize={14} fontWeight={400}>
            {`${startCount} - ${endCount} of ${totalCount}`}
          </PrimaryText>
        </View>

        <TouchableOpacity
          onPress={() =>
            page < totalPageCount ? nextPage(page, setPage) : null
          }
        >
          <FaIcons.FaChevronRight size={20} color={COLORS.primary.darkGray} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primary.white,
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
  tableContainer: {
    width: "100%",
  },
  button: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    width: 150,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    margin: 12,
    borderRadius: 6,
  },
});
