import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import Table from "components/Common/TableComponent/Table/Table";
import loginLeft from "assets/images/loginLeft.jpg";
import { useNavigate } from "react-router-dom";
import { useUsers } from "context/UserContext";
import {
  ActivityIndicator,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native-web";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import * as FaIcons from "react-icons/fa";
import { Links } from "constants/Links";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { useReported } from "context/ReportedContext";
import moment from "moment";
import { FiEdit } from "react-icons/fi";
import { FullModal } from "components/Modals/FullModal";
import { resolveReport } from "services/reported-service";

const { height } = Dimensions.get("window");
const pageSize = 10;

const options = [
  { value: "unresolved", label: "Unresolved" },
  { value: "resolved", label: "Resolved" },
];
const resolveOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const ReportedUsers = () => {
  const navigate = useNavigate();

  const { nextPage, loading, setSelectedUser, selectedView } = useUsers();
  const { reportedUsers, filter, setFilter } = useReported();

  // Table related part
  const [page, setPage] = useState(1);

  const [selectedItem, setSelectedItem] = useState(null);

  function userSelected(user) {
    setSelectedUser(user);
    navigate(Links.UserDetail);
  }

  function changeResolvedStatus(value) {
    selectedItem.resolved = value;
    resolveReport(selectedItem, value);
    const findIndex = reportedUsers.findIndex(x => x.id === selectedItem.id);
    reportedUsers[findIndex] = selectedItem;
    setSelectedItem(null);
  }

  const currentTableData = () => {
    const firstPageIndex = (page - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return reportedUsers.slice(firstPageIndex, lastPageIndex);
  };

  const totalCount = reportedUsers?.length;
  const totalPageCount = Math.ceil(totalCount / pageSize);
  const startCount = (page - 1) * pageSize + 1;
  const endCount = startCount + currentTableData()?.length - 1;

  if (loading) {
    return (
      <View
        style={{
          height: height - 100,
          width: "100%",
          flexDirection: "column",
          backgroundColor: COLORS.primary.white,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={COLORS.primary.blue}
        />
      </View>
    );
  }

  return (
    <View style={styles.tableContainer}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingHorizontal: 24,
          marginTop: 12,
        }}
      >
        {/* <FaIcons.FaSearch size={16} color={COLORS.primary.darkGray} /> */}
        <div className="flex-1" style={{ marginRight: 12 }}>
          {/* <FormInput
            type="search"
            placeholder="Search user by name"
            value={search}
            setValue={setSearch}
          /> */}
        </div>
        <CustomPicker
          style={{ maxWidth: 175 }}
          mandatory
          value={filter}
          onChange={(value) => setFilter(value)}
          options={options}
        />
      </View>
      {currentTableData() && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <PrimaryText fontSize={20} fontWeight={800}></PrimaryText>
              </TableCell>
              <TableCell>
                <PrimaryText fontSize={20} fontWeight={800}>
                  Reported User
                </PrimaryText>
              </TableCell>
              <TableCell>
                <PrimaryText fontSize={20} fontWeight={800}>
                  Reported By
                </PrimaryText>
              </TableCell>

              <TableCell>
                <PrimaryText fontSize={20} fontWeight={800}>
                  Date
                </PrimaryText>
              </TableCell>

              <TableCell>
                <PrimaryText fontSize={20} fontWeight={800}>
                  Reason
                </PrimaryText>
              </TableCell>
              <TableCell>
                <PrimaryText fontSize={20} fontWeight={800}>
                  Additional
                </PrimaryText>
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                <PrimaryText fontSize={20} fontWeight={800}>
                  Resolved
                </PrimaryText>
              </TableCell>
              <TableCell style={{ textAlign: "center" }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {currentTableData().map((item, index) => {
              return (
                <TableRow
                  hover
                  key={item.id}
                  onClick={() => userSelected(item.reportedUser)}
                >
                  <TableCell style={{ height: 50, padding: 0 }}>
                    <img
                      src={
                        item.reportedUser.image
                          ? item.reportedUser.image
                          : loginLeft
                      }
                      style={{
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {item?.reportedUser?.firstName}{" "}
                      {item?.reportedUser?.lastName}
                    </PrimaryText>
                  </TableCell>
                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {item?.user?.firstName} {item?.user?.lastName}
                    </PrimaryText>
                  </TableCell>

                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {moment(item?.createdAt.toDate()).format("MMM DD, YYYY")}
                    </PrimaryText>
                  </TableCell>

                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {item?.reason}
                    </PrimaryText>
                  </TableCell>
                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {item.additional}
                    </PrimaryText>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {item.resolved ? "Yes" : "No"}
                    </PrimaryText>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <TouchableOpacity onPress={() => setSelectedItem(item)}>
                      <FiEdit size={20} color={COLORS.primary.darkBlue} />
                    </TouchableOpacity>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      <View
        style={{ flexDirection: "row", alignSelf: "flex-end", marginTop: 12 }}
      >
        <TouchableOpacity
          onPress={() =>
            selectedView === "all" && page > 1 ? setPage(page - 1) : null
          }
        >
          <FaIcons.FaChevronLeft size={20} color={COLORS.primary.darkGray} />
        </TouchableOpacity>

        <View style={{ width: 125, alignItems: "center" }}>
          <PrimaryText fontSize={14} fontWeight={400}>
            {`${startCount} - ${endCount} of ${totalCount}`}
          </PrimaryText>
        </View>

        <TouchableOpacity
          onPress={() =>
            page < totalPageCount ? nextPage(page, setPage) : null
          }
        >
          <FaIcons.FaChevronRight size={20} color={COLORS.primary.darkGray} />
        </TouchableOpacity>
      </View>
      {!!selectedItem && (
        <FullModal 
        setShowModal={() => setSelectedItem(null)}
        title={"Resolved"}
        >
          <View style={styles.modalContainer}>
            <CustomPicker
              style={{ maxWidth: 175 }}
              mandatory
              value={!!selectedItem?.resolved}
              onChange={(value) => changeResolvedStatus(value)}
              options={resolveOptions}
            />
          </View>
        </FullModal>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primary.white,
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
  tableContainer: {
    width: "100%",
  },
  button: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    width: 150,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    margin: 12,
    borderRadius: 6,
  },
  modalContainer: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 12
  },
});
