import Geocode from "react-geocode"

export async function formatUserReport(data) {
  Geocode.setApiKey("AIzaSyBKToZiSEjiW-U4gzpfqcoVf--l3t7j9ew")
  const temp = []
  let promise = data.map((x) => getCityStatePromise(x))
  return Promise.all(promise)
  //   .then((usersWithLocation) => {
  //     usersWithLocation.forEach((item) => {
  //       temp.push({
  //         Name: item.firstName,
  //         Gender: item.gender,
  //         GenderPreference: item.matchPreferenceGender,
  //         Profession: item.profession,
  //         City: item.city,
  //         State: item.state,
  //         Country: item.country,
  //         ResX: item.maxStockQty,
  //         SearchRadius: item.radius,
  //       })
  //     })
  //     return temp
  //   })
}

export function getCityStatePromise(item) {
  return new Promise((resolve, reject) => {
    Geocode.fromLatLng(item.lat, item.lng)
      .then(
        (response) => {
          const address = response.results[0].formatted_address
          let city, state, country
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name
                  break
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name
                  break
                case "country":
                  country = response.results[0].address_components[i].long_name
                  break
              }
            }
          }
          item.city = city
          item.state = state
          item.country = country
          resolve(item)
          //   console.log(city, state, country);
          //   console.log(address);
        },
        (error) => {
          item.city = item.currentCity
          item.state = ""
          item.country = ""
          resolve(item)
          console.error("location error", error)
        }
      )
      .catch((e) => {
        item.city = ""
        item.state = ""
        item.country = ""
        resolve(item)
        console.error("location e", e)
      })
  })
}
