import React, { createContext, useContext, useEffect, useState } from "react";
import { getHeardAboutUs } from "services/heardAboutUs-service";

export const HeardAboutUsContext = createContext();

export const HeardAboutUsProvider = ({ children }) => {
  const [feedback, setFeedback] = useState([]);
  const [feedbackUsers, setFeedbackUsers] = useState([]);
  const [filter, setFilter] = useState("unresolved");

  const [feedBackByType, setFeedBackByType] = useState([]);

  useEffect(() => {
    getHeardAboutUs(setFeedbackUsers);
  }, []);

  useEffect(() => {
    if (!feedbackUsers.length) return;
    // count feedback by type
    const newArr = []
    const feedbackByType = feedbackUsers.reduce((acc, curr) => {
      const type = curr.hearAboutUs;
      if (!newArr.find(x => x.hearAboutUs === type)) {
        newArr.push({ hearAboutUs: type, count: 1 });
      } else {
        const findIndex = newArr.findIndex(x => x.hearAboutUs === type);
        newArr[findIndex].count++;
      }
      return newArr.sort((a, b) => (a.count < b.count ? 1 : -1));
    })
    setFeedBackByType(feedbackByType);

  }, [feedbackUsers]);

  return (
    <HeardAboutUsContext.Provider
      value={{
        filter,
        setFilter,
        feedbackUsers,
        setFeedbackUsers,
        feedBackByType
      }}
    >
      {children}
    </HeardAboutUsContext.Provider>
  );
};

export const useHeardAboutUs = () => useContext(HeardAboutUsContext);
