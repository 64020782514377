import {
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";
import Table from "components/Common/TableComponent/Table/Table";
import loginLeft from "assets/images/loginLeft.jpg";
import { useNavigate } from "react-router-dom";
import { useUsers } from "context/UserContext";
import {
  ActivityIndicator,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native-web";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import * as FaIcons from "react-icons/fa";
import { Links } from "constants/Links";
import { CustomPicker } from "components/Inputs/CustomPicker";
import moment from "moment";
import { FiEdit } from "react-icons/fi";
import { FullModal } from "components/Modals/FullModal";
import { resolveReport } from "services/reported-service";
import { useFeedback } from "context/FeedbackContext";
import DashboardItem from "screens/Dashboard/DashboardItem/DashboardItem";
import { useHeardAboutUs } from "context/HeardAboutUsContext";
import { hearAboutUsOptions } from "constants/data";

const { height } = Dimensions.get("window");
const pageSize = 10;

const options = [
  { value: "unresolved", label: "Unresolved" },
  { value: "resolved", label: "Resolved" },
];
const resolveOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const HearAboutUs = () => {
  const navigate = useNavigate();

  const { nextPage, loading, setSelectedUser, selectedView } = useUsers();
  const { feedbackUsers, feedBackByType } = useHeardAboutUs();

  console.log("yay", feedBackByType?.length, feedBackByType);

  // Table related part
  const [page, setPage] = useState(1);

  const [selectedItem, setSelectedItem] = useState(null);

  function userSelected(user) {
    setSelectedUser(user);
    navigate(Links.UserDetail);
  }

  function changeResolvedStatus(value) {
    selectedItem.resolved = value;
    resolveReport(selectedItem, value);
    const findIndex = feedbackUsers.findIndex((x) => x.id === selectedItem.id);
    feedbackUsers[findIndex] = selectedItem;
    setSelectedItem(null);
  }

  const currentTableData = () => {
    const firstPageIndex = (page - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return feedbackUsers.slice(firstPageIndex, lastPageIndex);
  };

  const totalCount = feedbackUsers?.length;
  const totalPageCount = Math.ceil(totalCount / pageSize);
  const startCount = (page - 1) * pageSize + 1;
  const endCount = startCount + currentTableData()?.length - 1;

  if (loading) {
    return (
      <View
        style={{
          height: height - 100,
          width: "100%",
          flexDirection: "column",
          backgroundColor: COLORS.primary.white,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={COLORS.primary.blue}
        />
      </View>
    );
  }

  return (
    <View style={styles.tableContainer}>
      <Grid item container xs={12} className="dashboardRow">
        {feedBackByType.map((item, index) => (
            <DashboardItem>
              <PrimaryText
                fontSize={20}
                fontWeight={700}
                style={{ marginVertical: 12 }}
              >
                {hearAboutUsOptions.find((x) => x.id === item?.hearAboutUs)?.name}
              </PrimaryText>
              <PrimaryText fontSize={36} fontWeight={800}>
                {item?.count}
              </PrimaryText>
            </DashboardItem>
          ))}
      </Grid>

      {currentTableData() && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <PrimaryText fontSize={20} fontWeight={800}></PrimaryText>
              </TableCell>
              <TableCell>
                <PrimaryText fontSize={20} fontWeight={800}>
                  User
                </PrimaryText>
              </TableCell>

              <TableCell>
                <PrimaryText fontSize={20} fontWeight={800}>
                  Date
                </PrimaryText>
              </TableCell>

              <TableCell>
                <PrimaryText fontSize={20} fontWeight={800}>
                  Heard About Us
                </PrimaryText>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentTableData().map((item, index) => {
              return (
                <TableRow
                  hover
                  key={item.id}
                  onClick={() => {
                    userSelected(item.user);
                  }}
                >
                  <TableCell style={{ height: 50, padding: 0 }}>
                    <img
                      src={item.user.image ? item.user.image : loginLeft}
                      style={{
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {item?.user?.firstName} {item?.user?.lastName}
                    </PrimaryText>
                  </TableCell>

                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {moment(item?.createdAt.toDate()).format("MMM DD, YYYY")}
                    </PrimaryText>
                  </TableCell>

                  <TableCell>
                    <PrimaryText fontSize={14} fontWeight={400}>
                      {
                        hearAboutUsOptions.find(
                          (x) => x.id === item?.hearAboutUs
                        )?.name
                      }
                    </PrimaryText>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      <View
        style={{ flexDirection: "row", alignSelf: "flex-end", marginTop: 12 }}
      >
        <TouchableOpacity onPress={() => (page > 1 ? setPage(page - 1) : null)}>
          <FaIcons.FaChevronLeft size={20} color={COLORS.primary.darkGray} />
        </TouchableOpacity>

        <View style={{ width: 125, alignItems: "center" }}>
          <PrimaryText fontSize={14} fontWeight={400}>
            {`${startCount} - ${endCount} of ${totalCount}`}
          </PrimaryText>
        </View>

        <TouchableOpacity
          onPress={() => setPage(page + 1)}
          disabled={page === totalPageCount}
        >
          <FaIcons.FaChevronRight size={20} color={COLORS.primary.darkGray} />
        </TouchableOpacity>
      </View>
      {!!selectedItem && (
        <FullModal
          setShowModal={() => setSelectedItem(null)}
          title={"Resolved"}
        >
          <View style={styles.modalContainer}>
            <CustomPicker
              style={{ maxWidth: 175 }}
              mandatory
              value={!!selectedItem?.resolved}
              onChange={(value) => changeResolvedStatus(value)}
              options={resolveOptions}
            />
          </View>
        </FullModal>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primary.white,
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
  tableContainer: {
    width: "100%",
  },
  button: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    width: 150,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    margin: 12,
    borderRadius: 6,
  },
  modalContainer: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 12,
  },
});
